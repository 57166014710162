import React, { useCallback } from 'react'
import { Box, Text } from '@chakra-ui/react'
import Button from 'components/atoms/Button'
import { purple, white } from '@qirapagos/lib/theme/colors'
import { BorderlessField } from 'components'
import { useForm } from 'react-hook-form'
import {
  ViewContainer,
  ViewContainerInformation,
  TextFullName,
  ViewInformation,
  TextInfo
} from './styles'

interface Props {
  clearErrors: any
  errors: any
  setIndex: (value: number) => void
  titlesInfo: any
}

export const StepTwo: React.FC<Props> = ({
  clearErrors,
  errors,
  setIndex,
  titlesInfo
}) => {
  const { getValues, control } = useForm()

  const observations = getValues('observation')

  const onPress = useCallback(() => {
    setIndex(2)
    titlesInfo.setDataThirdStep(() => ({
      ...titlesInfo.dataSecondStep,
      observations
    }))
    titlesInfo.setComplete(true)
  }, [observations, setIndex, titlesInfo])

  return (
    <Box bg={white} display="flex" flexDirection="column">
      <ViewContainer>
        <TextFullName>{titlesInfo?.dataSecondStep?.name}</TextFullName>
        <ViewContainerInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              Alias
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.alias}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CBU / CVU
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.cvu}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              Banco
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.bank}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <Text
              color={purple}
              fontWeight={600}
              fontSize={{ sm: '22px', md: '1.8vw', lg: '18px' }}
              align="center"
            >
              CUIT / CUIL
            </Text>
            <TextInfo>{titlesInfo?.dataSecondStep?.contactId}</TextInfo>
          </ViewInformation>
          <ViewInformation>
            <BorderlessField
              control={control}
              placeholder="Observaciones"
              placeholderTextColor="gray"
              testID="observations-field"
              clearErrors={clearErrors}
              error={errors.observation}
              errorMessage={{
                maxLenght: 'Debe contener menos de 20 caracteres'
              }}
              name="observation"
              rules={{
                maxLenght: 20
              }}
            />
          </ViewInformation>
        </ViewContainerInformation>
      </ViewContainer>
      <Button
        disabled={false}
        onPress={onPress}
        customStyle={{ width: '15%', alignSelf: 'flex-end', marginRight: 30 }}
      >
        Agendar
      </Button>
    </Box>
  )
}

export default StepTwo
