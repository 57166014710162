import React from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useSelector } from 'react-redux'
import { formatDate } from '@qirapagos/lib/utils/common'
import { CustomList } from 'components'
import { purple } from '@qirapagos/lib/theme/colors'
import { styles } from './styles'

const TransferInfoList: React.FC = () => {
  const { data: drawerData } = useSelector((state: any) => state.drawer)
  const { businessName } = useSelector((state: any) => state.auth)

  if (Object.keys(drawerData.RECEIPT_DETAIL).length) {
    const { date, amount, cbu, cuit, id, owner } =
      drawerData.RECEIPT_DETAIL || {}

    const data: any = [
      { 'Fecha de transferencia': formatDate(date) },
      { Cuenta: businessName },
      { Monto: `$${amount.toLocaleString('es-AR')}` },
      { 'Transferido a': owner },
      { 'CBU / CVU ': cbu },
      { CUIT: cuit },
      { Concepto: 'Varios' },
      { 'Número de operación': id }
    ]

    switch (drawerData.RECEIPT_DETAIL.movementType.id) {
      case 1:
        data[1] = { 'Cuenta débito': businessName }
        data[3] = { 'Transferido a': owner }
        data[4] = { 'CBU / CVU Destino ': cbu }
        break
      case 2:
        data[1] = { 'Cuenta crédito': businessName }
        data[3] = {
          'Recibido de': owner.includes('undefined') ? 'Sin datos' : owner
        }
        data[4] = { 'CBU / CVU Origen ': cbu }
        break
      case 46:
        data[0] = { 'Fecha de reverso': formatDate(date) }
        data[1] = { 'Cuenta crédito': businessName }
        data[3] = {
          'Reverso de': owner.includes('undefined') ? 'Sin datos' : owner
        }
        data[4] = { 'CBU / CVU Origen ': cbu }
        data[6] = { 'Concepto ': 'Reverso' }
        break
      default:
        break
    }

    return (
      <CustomList
        data={data}
        positioning="vertical"
        customStyle={{
          title: styles.title,
          flatList: styles.flatList,
          containerStyle: styles.contentStyle
        }}
      />
    )
  }

  return (
    <View style={styles.loading}>
      <ActivityIndicator color={purple} size="large" />
    </View>
  )
}

export default TransferInfoList
