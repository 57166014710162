import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const TransferReceive = (props: SvgProps) => (
  <Svg
    width="46"
    height="46"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <Circle
      cx="16"
      cy="16"
      r="13.638"
      stroke="#7D8893"
      strokeWidth="1.524"
    />
    <Path
      stroke="#7D8893"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.286"
      d="m20.159 13.304-8.46 5.542M17.379 20.07l-5.68-1.224 1.242-5.76"
    />
  </Svg>
)

export default TransferReceive
