import React, { createContext, useCallback, useMemo, useState } from 'react'
import { IContact, IMovement } from '@qirapagos/lib/store/transfers/types'
import { FilterType } from 'constants/filters'
import { SaleOrderCreated } from 'store/contracts/types'
import { showDrawer } from 'store/drawer/actions'
import { useDispatch } from 'react-redux'
import { Drawers } from 'store/drawer/types'
import { IRedeemer } from 'store/menu/types'
import { MovementsTypes } from 'constants/movements'
import { setHours, setMinutes } from 'date-fns'
import { ITableContext, ITableProviderProps } from './types'
import { ISignatureStatustypes, SignatureDocument } from 'store/signature/types'

export const TableContext = createContext<ITableContext>({
  selectedFilter: 'Todos los movimientos',
  selectedDateFilter: 'Filtrar por fecha',
  handleDateFilterType: () => null,
  handleFilterType: () => null,
  handleDatesToFilter: () => null,
  handleFilterMovements: () => [],
  handleFilterOperations: () => [],
  handleFilterByDate: () => [],
  handleFilterContacts: () => [],
  handleFilterDocuments: () => [],
  handleDrawer: () => null,
  onChangeFilter: () => null,
  onChangeFilterContact: () => null,
  filterRedeemers: () => [],
  filterContacts: () => [],
  filterRedeemerValue: '',
  filterContactValue: '',
  datesToFilter: {
    dateFrom: undefined,
    dateTo: undefined
  },
  handleRestartDateFilterCb: () => null,
  restartDateFilterCb: undefined
})

export const TableProvider = ({ children }: ITableProviderProps) => {
  const dispatch = useDispatch()
  const [filterRedeemerValue, setFilterRedeemerValue] = useState<string>('')
  const [filterContactValue, setFilterContactValue] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState('Filtrar por estado')
  const [selectedDateFilter, setSelectedDateFilter] = useState('Filtrar por fecha')
  const [restartDateFilterCb, setRestartDateFilterCb] = useState<() => void>()

  const handleRestartDateFilterCb = (callback: () => void) => {
    setRestartDateFilterCb(() => callback)
  }

  const [datesToFilter, setDatesToFilter] = useState({
    dateFrom: new Date(),
    dateTo: new Date()
  })

  const handleFilterType = (filter: string) => {
    setSelectedFilter(filter)
  }

  const handleDateFilterType = (filter: string) => {
    setSelectedDateFilter(filter)
  }

  const handleDatesToFilter = (dateFrom: Date, dateTo: Date) => {
    setDatesToFilter({ dateFrom, dateTo })
  }

  const handleFilterByDate = useCallback(
    (data: IMovement[], dateFrom: Date, dateTo: Date) => {
      return data?.filter(item => {
        const date = new Date(item.date.substring(0, 19))
        const dateToEndOfDay = setMinutes(setHours(dateTo, 23), 59)
        const DATES_TO_FILTER = date >= dateFrom && date <= dateToEndOfDay
        if (selectedFilter === FilterType.INCOMING && DATES_TO_FILTER) {
          return [
            MovementsTypes.DEBIT_TRANSFER_QIRA,
            MovementsTypes.DEBIT_EXTERNAL_TRANSFERS,
            MovementsTypes.DEBIT_TAX,
            MovementsTypes.DEBIT_RETURNS
          ].includes(item.movementType.id)
        }
        if (selectedFilter === FilterType.OUTGOING && DATES_TO_FILTER) {
          return [
            MovementsTypes.CREDIT_TRANSFER_QIRA,
            MovementsTypes.CREDIT_EXTERNAL_TRANSFERS,
            MovementsTypes.CREDIT_TAX_COLLECTION,
            MovementsTypes.CREDIT_RETURNS
          ].includes(item.movementType.id)
        }
        return date >= dateFrom && date <= dateToEndOfDay
      })
    },
    [selectedFilter]
  )

  const handleFilterMovements = useCallback(
    (data: IMovement[]) => {
      return data?.filter(item => {
        if (selectedFilter === FilterType.INCOMING) {
          return [
            MovementsTypes.DEBIT_TRANSFER_QIRA,
            MovementsTypes.DEBIT_EXTERNAL_TRANSFERS,
            MovementsTypes.DEBIT_TAX,
            MovementsTypes.DEBIT_RETURNS
          ].includes(item.movementType.id)
        }
        if (selectedFilter === FilterType.OUTGOING) {
          return [
            MovementsTypes.CREDIT_TRANSFER_QIRA,
            MovementsTypes.CREDIT_EXTERNAL_TRANSFERS,
            MovementsTypes.CREDIT_TAX_COLLECTION,
            MovementsTypes.CREDIT_RETURNS
          ].includes(item.movementType.id)
        }
        return item
      })
    },
    [selectedFilter]
  )

  const handleFilterContacts = useCallback(
    (data: any) => {
      if (filterRedeemerValue.length >= 1) {
        data.filter((item: any) =>
          Object.values(item).some(value => {
            return (
              typeof value === 'string' &&
              value.toLowerCase().includes(filterRedeemerValue.toLowerCase())
            )
          })
        )
      }

      return data?.filter((item: any) =>
        Object.values(item).some(value => {
          return (
            typeof value === 'string' &&
            value.toLowerCase().includes(filterRedeemerValue.toLowerCase())
          )
        })
      )
    },
    [filterRedeemerValue]
  )

  const handleFilterOperations = useCallback(
    (data: SaleOrderCreated[]) => {
      return data?.filter(item => {
        return item
      })
    },
    []
  )

  const handleFilterDocumentsByDate = useCallback(
    (data: SignatureDocument[], dateFrom: Date, dateTo: Date) => {
      return data?.filter(item => {
        const documentDate = new Date(item.processStartDate)
        const adjustedDocumentDate = new Date(documentDate.getTime() - documentDate.getTimezoneOffset() * 60000)
        const dateToEndOfDay = setMinutes(setHours(dateTo, 23), 59)

        return (adjustedDocumentDate >= dateFrom && adjustedDocumentDate <= dateToEndOfDay)
      })
    },
    []
  )

  const handleFilterDocuments = useCallback(
    (data: SignatureDocument[]) => {
      let filteredData: SignatureDocument[] = []
      if (selectedDateFilter !== FilterType.ALL_DATES_DOCUMENTS) {
        const filteredDataByDate = handleFilterDocumentsByDate(
          data,
          datesToFilter.dateFrom,
          datesToFilter.dateTo
        )
        filteredData = filteredDataByDate
      } else { filteredData = data }
      const result = filteredData?.filter(item => {
        switch (selectedFilter) {
          case FilterType.SIGNED:
            return item.signingProcessStatusId === ISignatureStatustypes.SIGNED
          case FilterType.PENDING_SIGNATURE:
            return item.signingProcessStatusId === ISignatureStatustypes.PENDING_SIGNATURE
          default:
          // If no filter is selected, return all items
            return true
        }
      })
      return result
    },
    [selectedDateFilter, handleFilterDocumentsByDate, datesToFilter.dateFrom, datesToFilter.dateTo, selectedFilter]
  )

  const filterRedeemers = useCallback(
    (data: IRedeemer[]) => {
      return data?.filter(item =>
        Object.values(item).some(value => {
          return (
            typeof value === 'string' &&
            value.toLowerCase().includes(filterRedeemerValue.toLowerCase())
          )
        })
      )
    },
    [filterRedeemerValue]
  )

  const filterContacts = useCallback(
    (data: IContact[]) => {
      return data?.filter(item =>
        Object.values(item).some(value => {
          return (
            typeof value === 'string' &&
            value.toLowerCase().includes(filterContactValue.toLowerCase())
          )
        })
      )
    },
    [filterContactValue]
  )

  const onChangeFilter = (value: string) => {
    setFilterRedeemerValue(value)
  }

  const onChangeFilterContact = (value: string) => {
    setFilterContactValue(value)
  }

  const values = useMemo(
    () => ({
      selectedFilter,
      selectedDateFilter,
      handleFilterType,
      handleDateFilterType,
      handleFilterMovements,
      handleFilterOperations,
      handleFilterByDate,
      handleDrawer: () => dispatch(showDrawer(Drawers.FILTER_DATE)),
      handleDatesToFilter,
      filterRedeemers,
      filterContacts,
      onChangeFilter,
      onChangeFilterContact,
      filterRedeemerValue,
      filterContactValue,
      handleFilterDocuments,
      handleFilterContacts,
      datesToFilter,
      handleRestartDateFilterCb,
      restartDateFilterCb
    }),
    [
      selectedFilter,
      selectedDateFilter,
      handleFilterMovements,
      handleFilterOperations,
      handleFilterByDate,
      filterRedeemers,
      filterContacts,
      filterRedeemerValue,
      filterContactValue,
      handleFilterDocuments,
      handleFilterContacts,
      datesToFilter,
      dispatch,
      restartDateFilterCb
    ]
  )

  return (
    <TableContext.Provider value={values}>{children}</TableContext.Provider>
  )
}
