import styled from 'styled-components'
import { DEVICES } from 'theme/devicesSizes'
import { yellowFour, red } from '@qirapagos/web/src/theme/colors'

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 80px;
  padding: 1.5%;
  border-bottom: 1.5px solid #f0f0f0;
`

export const BoxIcon = styled.div`
  margin-right: 8px;
  align-self: center;
`

export const BoxLeftInfo = styled.div`
  width: 55%;
  margin-right: 5%;
  display: flex;
  flex-direction: row;
`

export const BoxLeftText = styled.div`
  display: flex;
  flex: 1;
  min-width: 130px;
  flex-direction: column;
`

export const BoxRightInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 35%;
`

export const BoxRightText = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30%;
  width: 80%;
  text-align: center;
`

export const PendingText = styled.p`
  font-weight: 600;
  font-size: 8px;
  color: ${yellowFour};
`

export const CancelText = styled.p`
  font-weight: 600;
  font-size: 8px;
  color: ${red};
`

export const TransferOwner = styled.p`
  font-weight: 400;
  font-size: 12px;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;

  @media ${DEVICES.tablet} {
    font-size: 14px;
    max-width: 380px;
  }
`
