import * as React from 'react'

const TransferTax = (props) => (
  <svg
    width="46"
    height="46"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle
      cx="16"
      cy="16"
      r="13.638"
      stroke="#7D8893"
      strokeWidth="1.524"
    />
    <path
      stroke="#7D8893"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.524"
      // eslint-disable-next-line max-len
      d="M12.346 14.319h7.04M12.346 16.51h7.04M9.366 21.438V11.033a.55.55 0 0 1 .16-.387.54.54 0 0 1 .382-.16h11.916c.143 0 .281.057.383.16a.55.55 0 0 1 .158.387v10.405L20.2 20.343l-2.167 1.095-2.166-1.095-2.166 1.095-2.167-1.095z"
    />
  </svg>
)

export default TransferTax
