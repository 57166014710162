import React from 'react'
import BackArrowIcon from '@qirapagos/web/src/assets/svg/BackArrow'
import { Drawers } from 'store/drawer/types'
import { TransferInfoList } from 'components'
import useSharePdf from 'hooks/useSharePdf'
import {
  Drawer,
  LogoContainer,
  ButtonContainer,
  DownloadButton,
  HeaderContainer,
  DrawerContainer,
  BackButton,
  DataContainer,
  SpinnerContainer,
  ToastRenderItem,
  ReceiptDetailText,
  PDFContainer,
  ErrorContainer,
  ErrorState,
  PendingState,
  PendingContainer,
  PendingLegend,
  ErrorLegend
} from './styles'
import wrapper from 'components/organisms/Drawers/wrapper'
import QirapagosLogoWeb from '@qirapagos/web/src/assets/svg/QirapagosLogo'
import { violet } from '@qirapagos/lib/theme/colors'
import { Spinner, useToast } from '@chakra-ui/react'
import { useAppSelector } from 'hooks/redux'
import SuccessTickWithBackground from 'assets/svg/SuccessTickWithBackground'
import useNavigation from 'hooks/useNavigation'
import { useLocation } from 'react-router-dom'
import { MovementStatus } from 'components/molecules/MovementItem/constants'
import TransferRejected from '@qirapagos/lib/assets/svg/TransferRejected'
import TransferPending from '@qirapagos/lib/assets/svg/TransferPending'
import { SaleOrder } from 'store/contracts/types'
import { MovementsTypes } from 'constants/movements'

interface Props {
  showDrawer: boolean
  onClose: () => void
}

interface DrawerData {
  id: number
  description: string
  destino: string
  source: string
  destination: string
  cuit: string
  cbu: string
  origenOper: number
  creditAccount: string
  debitAccount: string
  amount: number
  date: string
  status: number
  movementType: {
    id: number
    typeDescription: string
  }
  owner: string
  saleOrder: SaleOrder
}

const ReceiptDetail: React.FC<Props> = ({ showDrawer, onClose }) => {
  const { isLoading } = useAppSelector(state => state.transfers)
  const { data: drawerData } = useAppSelector((state) => state.drawer)
  const { onNavigate } = useNavigation()
  const location = useLocation()
  const toast = useToast({
    containerStyle: {
      width: '100%'
    }
  })
  const valuesDrawerData = Object.values(drawerData)[0] as DrawerData
  const { printRef, onShareHandler } = useSharePdf(220, 110)

  const handleClose = () => {
    if (location.pathname.includes('/content/your-account/new-transfer')) {
      onClose()
      onNavigate('/content/your-account/new-transfer', {
        title: 'Nueva transferencia',
        hasBackArrow: true
      })
    } else {
      onClose()
    }
  }

  const isApproved = valuesDrawerData.status === MovementStatus.APPROVED
  const isCancel = valuesDrawerData.status === MovementStatus.REJECTED ||
  valuesDrawerData.movementType.id === MovementsTypes.DEBIT_REVERSED
  const isPending = valuesDrawerData.status === MovementStatus.PENDING ||
                    valuesDrawerData.status === MovementStatus.UNKNOWN_FOREVER
  const isCreditAdjustment = valuesDrawerData.movementType.id === MovementsTypes.ADJUSTMENT_BALANCE_CREDIT

  return (
    <Drawer
      open={showDrawer}
      onClose={handleClose}
      direction="right"
    >
      {isLoading
        ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          )
        : (
          <DrawerContainer>
            <BackButton onClick={handleClose}>
              <BackArrowIcon />
            </BackButton>
            <PDFContainer ref={printRef}>
              <HeaderContainer>
                <LogoContainer>
                  <QirapagosLogoWeb color={violet} width={150} height={35} />
                  <ReceiptDetailText>Detalle de comprobante</ReceiptDetailText>
                </LogoContainer>
              </HeaderContainer>
              {isCancel && (
                <ErrorContainer>
                  <ErrorState>
                    <TransferRejected />
                    Operación cancelada/rechazada
                  </ErrorState>
                  <ErrorLegend>
                    No pudimos completar tu operación debido a un problema ajeno a nuestra App.
                    Por favor, intentá nuevamente más tarde.
                    Si el inconveniente persiste, contactanos para más información.
                  </ErrorLegend>
                </ErrorContainer>
              )}
              {isPending && (
                <PendingContainer>
                  <PendingState>
                    <TransferPending />
                    Operación pendiente
                  </PendingState>
                  <PendingLegend>
                    Tu operación está pendiente debido a demoras en el procesamiento.
                    Este tipo de operaciones puede tardar un poco más de lo habitual.
                    Consultanos más tarde.
                  </PendingLegend>
                </PendingContainer>
              )}
              <DataContainer>
                <TransferInfoList />
              </DataContainer>
            </PDFContainer>
            {isApproved && !isCancel && !isPending && !isCreditAdjustment && (
              <ButtonContainer>
                <DownloadButton
                  onPress={() => {
                    toast({
                      position: 'bottom',
                      render: () => (
                        <ToastRenderItem>
                          El comprobante se ha descargardo correctamente
                          <SuccessTickWithBackground />
                        </ToastRenderItem>
                      )
                    })
                    onShareHandler()
                  }}
                >
                  Descargar PDF
                </DownloadButton>
              </ButtonContainer>
            )}
          </DrawerContainer>
          )}
    </Drawer>
  )
}

export default wrapper(ReceiptDetail, Drawers.RECEIPT_DETAIL)
