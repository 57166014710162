import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BoxBalance, Table } from 'components'
import {
  getAccountInfo,
  getTransfers,
  getUserBalance
} from 'store/transfers/thunks'
import { configTransfers } from './constants'
import Helmet from 'components/utilities/Helmet'
import { SCREEN_TITLES } from 'utils/navigation'
import YourAccountSkeleton from 'assets/skeletons/YourAccountSkeleton'
import useNavigation from 'hooks/useNavigation'
import { useTable } from 'hooks/useTable'
import { FilterType } from 'constants/filters'
import { setShowDrawerStatement } from 'store/modals/actions'
import DownloadSVG from 'assets/svg/DownloadSVG'
import ActionCardList from 'components/organisms/ActionCardList'
import { ViewBoxBalanceContainer, ViewBoxContainer } from './style'
import { useAppSelector } from 'hooks/redux'
import { RootState } from 'store'
import { getAccountSummaries } from 'store/account/thunks'
import { SpinnerIcon } from 'components/organisms/Table/styles'

const YourAccount: React.FC = () => {
  const {
    isLoading,
    movements,
    balance,
    account
  } = useAppSelector(state => state.transfers)
  const { summaries, isLoading: summariesLoading } = useAppSelector((state: RootState) => state.account)

  const { handleFilterType, handleDateFilterType, handleFilterMovements, handleRestartDateFilterCb } = useTable()
  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()

  const handleOpenDrawer = () => {
    dispatch(setShowDrawerStatement(true))
  }

  useEffect(() => {
    if (!summaries) {
      dispatch(getAccountSummaries())
    }
  }, [dispatch, summaries])

  useEffect(() => {
    if (!balance) {
      dispatch(getUserBalance())
    }
    if (!account) {
      dispatch(getAccountInfo())
    }
    dispatch(getTransfers(10))
    handleRestartDateFilterCb(() => dispatch(getTransfers(10)))
    if (!movements) {
      handleFilterType(FilterType.ALL_MOVEMENTS)
      handleDateFilterType(FilterType.ALL_DATES)
    }
  }, [dispatch, balance, account])

  const handleOnPressButtonLimit = useCallback(() => {
    onNavigate('limit-transfer')
  }, [onNavigate])

  if (isLoading) return <YourAccountSkeleton investmentsFlag={false} />

  return (
    <>
      <Helmet title={SCREEN_TITLES.MY_ACCOUNT} />
      <ViewBoxBalanceContainer>
        <ViewBoxContainer>
          <BoxBalance
            title="Tu billetera"
            subtitle="Balance"
            buttonText="Limitar transferencias"
            onPressButton={handleOnPressButtonLimit}
          />
        </ViewBoxContainer>
        <ActionCardList />
      </ViewBoxBalanceContainer>
      <Table
        onClick={handleOpenDrawer}
        icon={summariesLoading ? <SpinnerIcon /> : <DownloadSVG />}
        data={handleFilterMovements(movements) || []}
        title="Movimientos"
        config={configTransfers}
        fromScreen="yourAccount"
        filterOptions={[
          FilterType.ALL_MOVEMENTS,
          FilterType.INCOMING,
          FilterType.OUTGOING
        ]}
        onFilter={handleFilterType}
        emptyText="No hay resultados para tu búsqueda"
      />
    </>
  )
}

export default YourAccount
