import React from 'react'
import { View } from 'react-native'
import Handshake from '@qirapagos/lib/assets/svg/Handshake'
import TransferReceive from '@qirapagos/lib/assets/svg/TransferReceive'
import TransferSend from '@qirapagos/lib/assets/svg/TransferSend'
import TransferPending from '@qirapagos/lib/assets/svg/TransferPending'
import TransferTax from '@qirapagos/lib/assets/svg/TransferTax'
import TransferRejected from '@qirapagos/lib/assets/svg/TransferRejected'
import DollarSignSVG from '../../../assets/svg/DollarSignSVG'
import TruckQuota from '../../../assets/svg/TruckQuota'
import { Text } from '@chakra-ui/react'
import GreenDollarSign from '../../../assets/svg/GreenDollarSign'
import { ActivityText, DocumentIcon, ViewContainer } from './styles'
import TicketSVG from '../../../assets/svg/TicketSVG'
import { MovementStatus, QuotaStatus, MovementTaxOwner } from './constants'
import CircleWithCheck from '../../../assets/svg/CircleWithCheck'
import HandshakeSVG from '../../../assets/svg/HandshakeSVG'
import { green, red, yellow } from 'theme/colors'
import { capitalizeNames } from 'utils/common'
import { error, yellowFour } from '@qirapagos/web/src/theme/colors'

interface Props {
  movementType: any
  owner?: string
  fromContracts?: boolean
  status?: string | number
}

export const getContractNumber = (title: any) => {
  if (title.typeDescription) return ''
  if (title.includes('#')) return title.split('#')[1]
  return ''
}

export const getTransferStatusText = (status: MovementStatus, destinationName?: string) => {
  switch (status) {
    case MovementStatus.APPROVED:
    case MovementStatus.REJECTED:
    case MovementStatus.PENDING:
    case MovementStatus.UNKNOWN_FOREVER:
      return (
        <ActivityText>
          {destinationName ? `Enviaste a ${capitalizeNames(destinationName)}` : 'Enviaste'}
        </ActivityText>
      )
    default:
      return ''
  }
}

export const getTransferStatusIcon = (status: MovementStatus) => {
  switch (status) {
    case MovementStatus.APPROVED:
      return <TransferSend />
    case MovementStatus.PENDING:
    case MovementStatus.UNKNOWN_FOREVER:
      return <TransferPending />
    case MovementStatus.REJECTED:
      return <TransferRejected />
    default:
      return <TransferSend />
  }
}

const MovementItem: React.FC<Props> = ({
  movementType,
  owner,
  fromContracts,
  status = ''
}) => {
  const getTitle = (title: string): React.ReactNode => {
    switch (title) {
      case 'Cupo':
        return `Cupo ${status}`
      case 'Crédito Transferencia':
      case 'Crédito Transferencia Otros Bancos/Billeteras':
        return (
          <ActivityText>
            {owner ? `Recibiste de ${capitalizeNames(owner)}` : ''}
          </ActivityText>
        )
      case 'Ajuste Saldo (Crédito)':
        if (Object.values(MovementTaxOwner).includes(owner as MovementTaxOwner)) return `Reverso ${owner}`
        return (
          <ActivityText>
            {owner ? `Reverso de ${capitalizeNames(owner)}` : ''}
          </ActivityText>
        )
      case 'Débito Transferencia':
      case 'Débito Transferencia Otros Bancos/Billeteras':
        return (
          <>
            <Text>
              {getTransferStatusText(status as MovementStatus, owner ? capitalizeNames(owner) : '')}
            </Text>
            {status === MovementStatus.REJECTED && (
              <Text color={error} fontSize="12" fontWeight={600}>
                Cancelada
              </Text>
            )}
            {(status === MovementStatus.PENDING || status === MovementStatus.UNKNOWN_FOREVER) && (
              <Text color={yellowFour} fontSize="12" fontWeight={600}>
                Pendiente
              </Text>
            )}
          </>
        )
      case 'Débito Reversado':
        if (Object.values(MovementTaxOwner).includes(owner as MovementTaxOwner)) return owner
        return (
          <Text>
            {getTransferStatusText(status as MovementStatus, owner ? capitalizeNames(owner) : '')}
          </Text>
        )
      case 'saleOrder':
        return 'Venta'
      case 'tradeOrder':
        return 'Compra'
      case 'Orden de venta':
        return 'Orden de venta'
      case 'Orden de canje':
        return 'Orden de canje'
      case `Venta #${getContractNumber(movementType)}`:
        return title
      case `Canje #${getContractNumber(movementType)}`:
        return title
      case 'priceFixation':
        return `${status}`
      case 'deliveries':
        return `${status}`
      case 'settlements':
        return `${status}`
      case 'Crédito Devoluciones':
        return 'Crédito Devoluciones'
      case 'documents':
        return `${status}`
      default:
        return title
    }
  }

  const getQuotaIcon = (status: string | number) => {
    if (status === QuotaStatus.GRANTED) return <TruckQuota color={green} />
    if (status === QuotaStatus.REQUESTED) return <TruckQuota color={yellow} />
    if (status === QuotaStatus.REJECTED) return <TruckQuota color={red} />
  }

  const getIcon = (title: string) => {
    switch (title) {
      case 'Cupo':
        return getQuotaIcon(status)
      case 'Crédito Transferencia':
      case 'Crédito Transferencia Otros Bancos/Billeteras':
      case 'Ajuste Saldo (Crédito)':
      case 'Crédito Rendimiento':
        return <TransferReceive />
      case 'Débito Transferencia':
      case 'Débito Transferencia Otros Bancos/Billeteras':
        return getTransferStatusIcon(status as MovementStatus)
      case 'Débito Reversado':
        if (Object.values(MovementTaxOwner).includes(owner as MovementTaxOwner)) return <TransferTax />
        return getTransferStatusIcon(status as MovementStatus)
      case 'saleOrder':
        return <DollarSignSVG active />
      case 'tradeOrder':
        return <Handshake />
      case 'Orden de venta':
        return <DollarSignSVG active />
      case 'Orden de canje':
        return <HandshakeSVG width={26} height={26} active />
      case `Venta #${getContractNumber(movementType)}`:
        return <DollarSignSVG active />
      case `Canje #${getContractNumber(movementType)}`:
        return <HandshakeSVG width={26} height={26} active />
      case 'priceFixation':
        return <CircleWithCheck />
      case 'settlements':
        return <GreenDollarSign />
      case 'deliveries':
        return <TicketSVG />
      case 'Crédito Devoluciones':
        return <TransferReceive />
      case 'Débito SIRCUPA RG 9/22':
      case 'Recaudación SIRCUPA RG 9/22':
      case 'Crédito Recaudación Impositivo':
      case 'Débito Impositivo':
        return <TransferTax />
      case 'documents':
        return <DocumentIcon />
      default:
        return status === MovementStatus.REJECTED && <TransferRejected />
    }
  }

  return fromContracts
    ? (
      <ViewContainer>
        <View>{getIcon(movementType)}</View>
        <Text fontWeight={550} fontSize={{ md: '13px', lg: '15px' }} ml={5}>
          {getTitle(movementType)}
        </Text>
      </ViewContainer>
      )
    : (
      <ViewContainer>
        <View>{getIcon(movementType.typeDescription.trim())}</View>
        <Text
          fontWeight={500}
          fontSize={{ md: '13px', lg: '16px' }}
          ml={5}
          data-testID="movement"
          id="movement"
        >
          {getTitle(movementType.typeDescription.trim())}
        </Text>
      </ViewContainer>
      )
}

export default MovementItem

MovementItem.defaultProps = {
  owner: '',
  fromContracts: false,
  status: ''
}
