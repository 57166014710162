import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const TransferPending = (props: SvgProps) => (
  <Svg
    width="46"
    height="46"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <Circle
      cx="16"
      cy="16"
      r="13.638"
      stroke="#7D8893"
      strokeWidth="1.524"
    />
    <Path
      stroke="#7D8893"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.286"
      d="m12.384 19.68 7.09-7.211M13.665 12.477l5.81-.008.008 5.891"
    />
    <Circle cx="25.143" cy="25.143" r="6.095" fill="#D68E38" />
    <Circle cx="24.713" cy="24.891" r="0.774" fill="#fff" />
    <Circle cx="27.442" cy="24.988" r="1.064" fill="#fff" />
    <Circle cx="22.066" cy="24.891" r="0.58" fill="#fff" />
  </Svg>
)

export default TransferPending
