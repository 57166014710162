import * as React from 'react'
import Svg, { SvgProps, Circle, Path } from 'react-native-svg'

const TransferRejected = (props: SvgProps) => (
  <Svg
    width="46"
    height="46"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <Circle
      cx="16"
      cy="16"
      r="13.638"
      stroke="#7D8893"
      strokeWidth="1.524"
    />
    <Path
      stroke="#7D8893"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.286"
      d="m12.384 19.68 7.09-7.211M13.665 12.477l5.81-.008.008 5.891"
    />
    <Circle cx="25.143" cy="25.143" r="6.095" fill="#FF5A3D" />
    <Path
      fill="#fff"
      fillRule="evenodd"
      // eslint-disable-next-line max-len
      d="M23.03 23.838a.571.571 0 0 1 .797-.82l1.366 1.328 1.366-1.327a.571.571 0 0 1 .797.82l-1.343 1.304 1.343 1.304a.571.571 0 1 1-.797.82l-1.366-1.327-1.366 1.327a.571.571 0 0 1-.796-.82l1.342-1.304z"
      clipRule="evenodd"
    />
  </Svg>
)

export default TransferRejected
