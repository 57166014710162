import React from 'react'

import { Text } from '@chakra-ui/react'
import { Colors } from '@qirapagos/lib/theme'
import { formatDateWithMonth } from '@qirapagos/lib/utils/common'
import {
  BoxContainer,
  BoxIcon,
  BoxLeftInfo,
  BoxLeftText,
  BoxRightInfo,
  BoxRightText,
  CancelText,
  PendingText,
  TransferOwner
} from './styles'
import { getInfo, TypeMovementsActivity } from './types'
import { ActivityMovement } from 'store/activity/types'
import { capitalizeNames } from 'utils/common'
import { MovementStatus } from '../MovementItem/constants'
import TransferPending from '@qirapagos/lib/assets/svg/TransferPending'

interface Props {
  activity: ActivityMovement
}

enum TransferActions {
  SENT = 'Enviaste',
}

const ActivityItem: React.FC<Props> = ({ activity }) => {
  const isTransfer =
    activity.movementType === TypeMovementsActivity.MOVEMENT_TRANSFER_DEBIT ||
    activity.movementType === TypeMovementsActivity.MOVEMENT_TRANSFER_CREDIT ||
    activity.movementType === TypeMovementsActivity.DEBIT_TRANSFER_FAILED ||
    activity.movementType === TypeMovementsActivity.DEBIT_REVERSED

  const isQuota =
    activity.movementType === TypeMovementsActivity.QUOTA_AWARDED ||
    activity.movementType === TypeMovementsActivity.QUOTA_CANCELED ||
    activity.movementType === TypeMovementsActivity.QUOTA_REJECTED ||
    activity.movementType === TypeMovementsActivity.QUOTA_REQUIRED

  const amount = isQuota ? `${activity.amount}TT` : `$${getInfo(activity).amount}`

  const activityFormatted = getInfo(activity)

  const { status, movementType } = activity

  const isCancel = status === MovementStatus.REJECTED || movementType === TypeMovementsActivity.DEBIT_REVERSED

  const isPending = status === MovementStatus.PENDING || status === MovementStatus.UNKNOWN_FOREVER

  return (
    <BoxContainer>
      <BoxLeftInfo>
        <BoxIcon>{isPending ? <TransferPending /> : activityFormatted.icon}</BoxIcon>
        <BoxLeftText>
          <Text fontWeight={600} fontSize={{ md: '14px', lg: '16px' }}>
            {activityFormatted.title}
          </Text>
          {isCancel && <CancelText>Cancelado</CancelText>}
          {isPending && <PendingText>Pendiente</PendingText>}
          <Text fontWeight={400} fontSize="14px">
            {amount}
          </Text>
        </BoxLeftText>
      </BoxLeftInfo>
      <BoxRightInfo>
        <BoxRightText>
          <Text
            fontWeight={400}
            fontSize="14px"
            color={Colors.gray}
            lineHeight="12px"
            mb={2}
            alignSelf="center"
          >
            {formatDateWithMonth(activityFormatted.date?.toString() ?? '')}
          </Text>
          {isTransfer && (
            <TransferOwner>
              {activityFormatted.title === TransferActions.SENT ? 'A ' : 'De '}
              {capitalizeNames(activityFormatted.owner ?? '')}
            </TransferOwner>
          )}
        </BoxRightText>
      </BoxRightInfo>
    </BoxContainer>
  )
}

export default ActivityItem
