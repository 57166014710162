import React, { useEffect } from 'react'
import {
  AccordionComponent,
  AccordionItemComponent,
  BoxNewAction,
  Table
} from 'components'
import PlusWithBackground from 'assets/svg/PlusWithBackground'
import {
  configApplications,
  configApplicationsContacts
} from 'screens/Home/YourAccount/NewTransfer/constants'
import { useDispatch } from 'react-redux'
import { getContacts, getTransfers } from 'store/transfers/thunks'
import { useAppSelector } from 'hooks/redux'
import YourAccountSkeleton from 'assets/skeletons/YourAccountSkeleton'
import { setShowDrawerStatement } from 'store/modals/actions'
import { IMovement } from '@qirapagos/lib/store/transfers/types'
import useNavigation from 'hooks/useNavigation'
import { FilterType } from 'constants/filters'
import { useTable } from 'hooks/useTable'
import { ViewAcordionContainer, ViewContainer } from './style'

const NewTransfer = () => {
  const { isLoading, movements, contacts } = useAppSelector(
    state => state.transfers
  )

  const { onNavigate } = useNavigation()
  const dispatch = useDispatch()

  const {
    handleDateFilterType,
    handleFilterContacts,
    filterContacts,
    filterRedeemerValue,
    filterContactValue,
    onChangeFilter,
    onChangeFilterContact,
    handleRestartDateFilterCb
  } = useTable()

  useEffect(() => {
    dispatch(getTransfers(100))
    dispatch(getContacts())
    handleDateFilterType(FilterType.ALL_DATES)
    handleRestartDateFilterCb(() => dispatch(getTransfers(100)))
  }, [dispatch])

  const handleOpenDrawer = () => {
    dispatch(setShowDrawerStatement(true))
  }

  const transferSended = movements?.filter((movement: IMovement) => {
    return (
      movement?.movementType?.typeDescription?.trim() ===
        'Débito Transferencia' ||
      movement?.movementType?.typeDescription?.trim() ===
        'Débito Transferencia Otros Bancos/Billeteras'
    )
  })

  const onPress = () => {
    onNavigate('transfer-steps', {
      title: 'Transferencia a nuevo contacto',
      hasBackArrow: true
    })
  }

  if (isLoading) return <YourAccountSkeleton />

  return (
    <ViewContainer>
      <BoxNewAction
        title="A nuevo contacto"
        icon={<PlusWithBackground />}
        onPress={onPress}
      />
      <ViewAcordionContainer>
        <AccordionComponent allowToggle defaultIndex={-1}>
          <AccordionItemComponent
            accordionTitle="Historial de transferencias"
            accordionBody={(
              <Table
                onClick={handleOpenDrawer}
                fromScreen="newTransfer"
                searchValue={filterRedeemerValue}
                onSearchText={onChangeFilter}
                data={handleFilterContacts(transferSended) || []}
                title=""
                config={configApplications}
                hasHowWorks={false}
                isTextFilter
                hasDateFilter
                showFilters={false}
                emptyText={'Aún no tenés ninguna \ntransferencia realizada'}
              />
            )}
            customStyle={{ marginBottom: 5 }}
          />
          <AccordionItemComponent
            accordionTitle="Mis contactos"
            accordionBody={(
              <Table
                fromScreen="newTransferContacts"
                searchValue={filterContactValue}
                data={filterContacts(contacts) || []}
                onSearchText={onChangeFilterContact}
                title=""
                config={configApplicationsContacts}
                hasHowWorks={false}
                emptyText={'Aún no tenés ningun \ncontacto agendado'}
                isTextFilter
                showFilters={false}
                hasDateFilter={false}
              />
            )}
          />
        </AccordionComponent>
      </ViewAcordionContainer>
    </ViewContainer>
  )
}

export default NewTransfer
