import styledNative from 'styled-components/native'
import styled from 'styled-components'
import { ReactComponent as Document } from 'assets/svg/Document.svg'
import { DEVICES } from 'theme/devicesSizes'

export const ViewContainer = styledNative.View`
  flex-direction: row;
  align-items: center;
`

export const DocumentIcon = styled(Document)`
  width: 30px;
  height: 30px;
`

export const ActivityText = styled.p`
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 350px;

  @media ${DEVICES.tablet} {
    font-size: 14px;
    max-width: 380px;
  }
`
