export enum MovementsTypes {
  DEBIT_TRANSFER_QIRA = 1,
  CREDIT_TRANSFER_QIRA = 2,
  DEBIT_AUTOMATIC = 3,
  DEBIT_EXTRACT_CASH = 4,
  DEBIT_SERVICES_PAYMENT = 5,
  DEBIT_BANKING_EXPENSES = 6,
  DEBIT_TAX = 7, // retenciones
  CASH_CREDIT = 8,
  CREDIT_RETURNS = 9,
  CREDIT_INTEREST = 10,
  CREDIT_EMISSION = 11,
  DEBIT_INTEREST = 12,
  DEBIT_QR_PAYMENT = 13,
  CREDIT_QR_PAYMENT = 14,
  DEBIT_BUY_API_MARKET_TC = 15,
  CREDIT_BUY_API_MARKET_TC = 16,
  DEBIT_BUY_TRADE = 17,
  CREDIT_BUY_TRADE = 18,
  DEBIT_SALE_WITH_CARD = 19,
  CREDIT_SALE_WITH_CARD = 20,
  DEBIT_SALE_PAYMENT_BUTTON = 21,
  CREDIT_SALE_PAYMENT_BUTTON = 22,
  DEBIT_SALE_WITH_CONTACTLESS = 23,
  CREDIT_SALE_WITH_CONTACTLESS = 24,
  DEBIT_SALE_WITH_MAGNETIC_READER_CARD = 25,
  CREDIT_SALE_WITH_MAGNETIC_READER_CARD = 26,
  DEBIT_WITH_CARD_CHIP = 27,
  CREDIT_WITH_CARD_CHIP = 28,
  DEBIT_BRANCH_SALE_AGENT = 29,
  CREDIT_BRANCH_SALE_AGENT = 30,
  DEBIT_ATM_SALE = 31,
  CREDIT_ATM_SALE = 32,
  CREDIT_TAX_COLLECTION = 33, // percepciones
  DEBIT_EXTERNAL_TRANSFERS = 34,
  CREDIT_EXTERNAL_TRANSFERS = 35,
  DEBIT_RETURNS = 36,
  ADJUSTMENT_BALANCE_CREDIT = 46,
  DEBIT_REVERSED = 49
}
