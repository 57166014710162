import styled from 'styled-components'
import ButtonComponent from 'components/atoms/Button'
import DrawerComponent from 'react-modern-drawer'
import { Colors, Fonts } from 'theme'
import QiraLogoReceiptOpacityLow from 'assets/images/QiraLogoReceiptOpacityLow.png'
import { Box } from '@chakra-ui/react'
import { purple, error, gray, errorSec, yellowSec, yellowFour } from '@qirapagos/web/src/theme/colors'

export const Drawer = styled(DrawerComponent)`
  width: 450px !important;
`

export const DrawerContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-items: center;
  overflow: auto;
`

export const PDFContainer = styled.div`
  width: 100%;
  justify-items: center;
`

export const HeaderContainer = styled.div`
  display: flex;
  min-height: 100px;
  padding-top: 90px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  background: white;
  z-index: 1;
`

export const DataContainer = styled.div`
  background-image: url(${QiraLogoReceiptOpacityLow});
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  justify-content: space-around;
  margin-bottom: 20;
`

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom-width: 0.2px;
  border-bottom-color: #000000;
  width: 100%;
  padding-left: 50px;
  padding-bottom: 25px;
`

export const BackButton = styled.button`
  z-index: 2;
  position: absolute;
  left: 20px;
  top: 40px;
`

export const ButtonContainer = styled.div`
  display: flex;
  height: 120px;
  margin-top: 4.5%;
  flex-direction: row;
  justify-content: center;
`

export const DownloadButton = styled(ButtonComponent)`
  height: 60px;
  font-family: ${Fonts.MANROPE};
`

export const ToastRenderItem = styled(Box)`
  display: flex;
  background-color: ${Colors.purple};
  color: ${Colors.white};
  padding: 10px;
  align-items: center;
  justify-content: space-around;
`

export const SpinnerContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border-width: 2px;
`

export const ReceiptDetailText = styled.p`
  font-family: ${Fonts.MANROPE};
  font-size: ${Fonts.SIZES.XMEDIUM};
  font-weight: 500;
  color: ${purple};
`

export const ErrorContainer = styled.div`
  text-align: center;
  background-color: ${errorSec};
  justify-content: center;
  padding-left: 65px;
  padding-right: 65px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
`

export const ErrorLegend = styled.div`
  color: ${gray};
  font-size: 14px;
`

export const ErrorState = styled.div`
  display: flex;
  justify-content: center;
  color: ${error};
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 20px;
  width: 100%;
`

export const PendingContainer = styled.div`
  text-align: center;
  background-color: ${yellowSec};
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 100%;
`
export const PendingLegend = styled.div`
  color: ${gray};
  font-size: 14px;
`

export const PendingState = styled.div`
  display: flex;
  justify-content: center;
  color: ${yellowFour};
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 20px;
  width: 100%;
`
